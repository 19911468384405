<template>
  <div
    tabindex="0"
    role="button"
    class="flex w-full items-center whitespace-nowrap px-4 py-1 text-left leading-8 hover:bg-gray-300 focus:outline-none"
    :class="disabled ? 'text-gray-400 cursor-not-allowed' : 'text-gray-700'"
    :disabled="disabled"
    @click="$emit('click')"
  >
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    disabled: Boolean,
  },

  emits: ['click'],
}
</script>
